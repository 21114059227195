import React, { Component } from 'react';
import './index.css';
import { spikkoAxios } from '../../axios';
import { Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import * as STRINGS from '../../strings/english';
import { ENUM } from '../../enum';

class Login extends Component {
    state = {
        mail: '',
        isRemember: false,
        isLoader: false,
        showLoginScreen: true,
    }

    usernameInput = 'spikko-wa-login-input-username';
    passwordInput = 'spikko-wa-login-input-password';

    enterPressed = event => {
        if (event.keyCode === 13 &&
            (event.target.id === this.usernameInput ||
                event.target.id === this.passwordInput)) {
            this.login();
        }
    }

    componentDidMount() {
        this.loginFromWaMain();
        document.addEventListener("keyup", this.enterPressed);
    }

    loginFromWaMain = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        const token = searchParams.get('token');
        const username = searchParams.get('username');
        if (token && username) {
            this.setState({
                showLoginScreen: false,
            })
            spikkoAxios.post('/Provisioning/WALogin', {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.data.UserType === ENUM.REGULAR_TYPE) {
                    localStorage.setItem('email', username);
                    localStorage.setItem('auth-data', token);
                    const data = {
                        allowedNumbersSessions: response.data.AllowedNumbersSessions,
                        parentUserId: response.data.ParentUserId,
                        parentUsername: response.data.ParentUsername,
                        newSessionCatchup: response.data.NewSessionCatchup,
                    }
                    this.props.history.replace('/', { redirect: true, data });
                }
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.enterPressed);
    }

    login = () => {
        if (this.exmail.value && this.expass.value) {
            this.setState({ mail: this.exmail.value.toLowerCase(), isLoader: true });
            spikkoAxios.post('/Provisioning/WALogin', {
                "username": this.exmail.value.toLowerCase(),
                "password": this.expass.value
            })
                .then(response => {
                    if (response && response.data) {
                        this.setState({ isLoader: false });
                        localStorage.setItem('email', this.exmail.value.toLowerCase());
                        localStorage.setItem('auth-data', response.data.HashedPassword);
                        if (response.data.UserType === ENUM.WA_API_TYPE ||
                            response.data.UserType === ENUM.AGENT_TYPE) {
                            this.props.history.push('/admin-screen', {
                                userType: response.data.UserType,
                                allowedUsersNumbers: response.data.AllowedUsersNumbers,
                                userId: response.data.UserId,
                            });
                        }
                        else {
                            const data = {
                                allowedNumbersSessions: response.data.AllowedNumbersSessions,
                                parentUserId: response.data.ParentUserId,
                                parentUsername: response.data.ParentUsername,
                                newSessionCatchup: response.data.NewSessionCatchup,
                            }
                            this.props.history.push('/', { redirect: true, data });
                        }
                    }
                })
                .catch(error => {
                    this.setState({ isLoader: false })
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
        }
        else {
            alert(STRINGS.MISSING_DETAILS);
        }
    }

    render() {
        if (this.state.showLoginScreen) {
            return (
                <div className="wrapper row justify-content-center m-0">
                    <Form className="loginForm col-3">
                        <h4>{STRINGS.LOGIN_BY_MAIL}</h4>
                        <FormGroup>
                            <Label for={this.usernameInput}>
                                {`${STRINGS.COMPANY} / ${STRINGS.ORGANIZATION_NAME}`}
                            </Label>
                            <Input
                                type="email"
                                name={this.usernameInput}
                                id={this.usernameInput}
                                innerRef={(exmail) => {
                                    this.exmail = exmail
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for={this.passwordInput}>
                                {STRINGS.PASSWORD}
                            </Label>
                            <Input
                                innerRef={(expass) => {
                                    this.expass = expass
                                }}
                                type="password"
                                name={this.passwordInput}
                                id={this.passwordInput}

                            />
                        </FormGroup>
                        <br />
                        <Button
                            id='login-sign-in-btn'
                            onClick={this.login}
                            size="lg"
                            block
                        >
                            {this.state.isLoader ?
                                <Spinner color="#075e54" /> : STRINGS.SIGN_IN}
                        </Button>
                    </Form>
                </div>
            );
        } else {
            return <React.Fragment />
        }
    }
}

export default Login;
