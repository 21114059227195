import React, { useEffect, useState, useCallback } from 'react';
import { Button, ModalHeader, ModalBody, Table, Spinner } from 'reactstrap';
import { getSessionsFromSpikko, setAsDisconnected } from '../../service/requests';
import * as STRINGS from '../../strings/english';
import { formatTime, formatDate, returnNumArr } from '../../service/tools';
import './sessionsModalContent.css';
import ReloadBtn from './reloadBtn';


export const SessionsModalContent = (props) => {

    const [sessions, setSessions] = useState([]);
    const [isLoader, setIsLoader] = useState(false);

    const getSessions = () => {
        setIsLoader(true);
        getSessionsFromSpikko(
            props.loginData.userType,
            returnNumArr(props.loginData.allowedUsersNumbers),
        ).then(res => {
            setIsLoader(false);
            if (res && res.data) {
                setSessions(res.data);
            }
        }).catch(error => {
            setIsLoader(false);
        })
    }

    const stableGetSessions = useCallback(
        getSessions,
        [
            props.loginData.userType,
            props.loginData.allowedUsersNumbers,
        ]);

    useEffect(() => {
        stableGetSessions();
    }, [stableGetSessions]);

    const setAsDisconnectedOnClick = (session) => {
        if (window.confirm(`${STRINGS.ARE_YOU_SURE} ?`)) {
            setAsDisconnected(session).then(res => {
                alert(`${STRINGS.UPDATE_SUCCESSFUL}.`);
                stableGetSessions();
            });
        }
    }

    return (
        <div>
            <ModalHeader>
                {`${STRINGS.SPIKKO_SESSIONS_FOR} `}
                <span className='sessions-modal-header-user-name'>
                    {localStorage.getItem('email')}
                </span>
            </ModalHeader>
            <ModalBody>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{STRINGS.USER_NAME}</th>
                            <th>{STRINGS.PHONE_NUMBER}</th>
                            <th>{STRINGS.ACTIVE}</th>
                            <th>{STRINGS.CONNECTION_OR_DISCONNECTION_TIME}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions.length > 0 ?
                            sessions.map((elm, i) => {
                                return (
                                    <tr key={`admin-screen-tr-${i}`}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{elm.Username}</td>
                                        <td>{elm.PhoneNumber}</td>
                                        <td>
                                            {elm.ClientStatus === 1 ? STRINGS.YES :
                                                elm.ClientStatus === 2 ? STRINGS.NO :
                                                    elm.ClientStatus === 4 ? `${STRINGS.RECONNECTING}...` :
                                                        '-'}</td>
                                        <td>
                                            {`${formatDate(elm.UpdateTime)} ${formatTime(elm.UpdateTime, true)}`}
                                        </td>
                                        <td>
                                            <ReloadBtn
                                                elm={elm}
                                                type='spikkoSessions'
                                                stableGetSessions={stableGetSessions}
                                            />
                                        </td>
                                        <th>
                                            <Button
                                                onClick={() => setAsDisconnectedOnClick(elm)}
                                                id='session-modal-set-as-disconnected-btn'
                                            >
                                                {STRINGS.SET_AS_DISCONNECTED}
                                            </Button>
                                        </th>
                                    </tr>
                                )
                            }) : isLoader ?
                                <tr>
                                    <td></td>
                                    <td>
                                        <Spinner color="#075e54" />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th></th>
                                </tr>
                                :
                                <tr>
                                    <td></td>
                                    <td>
                                        <h5 className='sessions-modal-no-data-msg'>
                                            {`${STRINGS.NO_DATA_TO_DISPLAY}...`}
                                        </h5>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th></th>
                                </tr>}
                    </tbody>
                </Table>
            </ModalBody>
        </div>
    )
}