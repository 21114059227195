export let initialStatus = {
    connection: false,
    agree: false,
    ready: false,
    noMatch: false,
    doubleEntrance: false,
    disconnected: false
}

export let initialLastMsgStatus = {
    sent: false,
    success: false,
}