import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.css';
import { Redirect } from 'react-router-dom';
import { FormGroup, Label, Input, Spinner, Button } from 'reactstrap';
import * as STRINGS from '../../strings/english';
import { spinnerStyleObj } from './style';
import { initialStatus } from './service';
import OldMessagesDropDown from './oldMessagesDropdown';

let QRCode = require('qrcode.react');


let serverApi = process.env.REACT_APP_WS_SERVER_PATH;

function Dashboard(props) {

    const [status, setStatus] = useState(initialStatus);
    const [qrCode, setQrCode] = useState('');
    const [isNewSession, setIsNewSession] = useState(false);
    const ws = useRef(null);

    const logout = (e) => {
        localStorage.clear();
        window.history.replaceState({}, document.title);
        props.history.push('/login');
    };

    const stableLogout = useCallback(logout, [props.history]);

    useEffect(() => {
        if (localStorage.getItem('auth-data') && props.location.state) {
            ws.current = new WebSocket(serverApi);
            ws.current.onopen = () => {
                console.log("ws opened");
                let primaryInformation = {
                    userName: localStorage.getItem('email'),
                    numbers: props.location.state ? props.location.state.data.allowedNumbersSessions : [],
                    organizationId: props.location.state.data.parentUserId,
                    organizationUsername: props.location.state.data.parentUsername,
                }
                ws.current.send(JSON.stringify(primaryInformation));
            };
            ws.current.onclose = (e) => {
                console.log("ws closed")
            };
            ws.current.onerror = (e) => {
                stableLogout();
            }

            return () => {
                ws.current.close();
            };
        }
    }, [stableLogout, props.location.state]);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            let object = JSON.parse(e.data);
            if (object.connection) {
                setStatus({ ...status, connection: true });
            }
            if (object.qrCode) {
                setQrCode(object.qrCode);
            }
            if (object.isReady) {
                if (!object.isReady.HasActiveSession) {
                    setStatus({ ...status, ready: true });
                    setIsNewSession(object.isNewSession);
                    localStorage.setItem('phone_number', object.isReady.PhoneNumber);
                    if (!object.isNewSession) {
                        setTimeout(() => {
                            stableLogout();
                        }, 4000);
                    }
                }
                else if (object.isReady.HasActiveSession) {
                    setStatus({ ...status, doubleEntrance: true });
                    setTimeout(() => {
                        stableLogout();
                    }, 4000);
                }
            }
            if (object.isReady === false) {
                setStatus({ ...status, noMatch: true });
            }
        };
    }, [stableLogout, status]);

    const OkButtonOnClick = () => {
        ws.current.send(JSON.stringify({
            restartClient: true
        }));
        setQrCode('');
        setStatus({ ...status, noMatch: false });
    }

    const checkboxOnChange = (e) => {
        setStatus({ ...status, agree: e.target.checked });
        ws.current.send(JSON.stringify({
            agree: true
        }))
    }


    if (localStorage.getItem('auth-data') && props.location.state) {
        return (
            <div className='d-flex'>
                <div className='dash-main'>
                    {!status.agree ?
                        <FormGroup check className='dash-form'>
                            <div className='dash-header'>
                                {`${STRINGS.TO_PROCEED_PLEASE_READ_AND_APPROVE_OUR_TERMS_OF_SERVICE}`}
                            </div>
                            <Label check>
                                <Input
                                    onChange={checkboxOnChange}
                                    type="checkbox"
                                />
                                <a
                                    className="dash-terms-of-use-link"
                                    href='https://mobile2crm.com/general-terms-and-conditions/'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {STRINGS.I_AGREE_TO_THE_TERMS_OF_USE}
                                </a >
                            </Label>
                        </FormGroup> :
                        (status.noMatch ?
                            <div className='dash-no-match-main'>
                                <div className='dash-no-match-text'>
                                    {STRINGS.NO_MATCH_MESSAGE}
                                </div>
                                <div className='dash-no-match-buttons'>
                                    <Button
                                        id='dash-cancel-btn'
                                        onClick={stableLogout}
                                    >
                                        {STRINGS.CANCEL}
                                    </Button>
                                    <Button
                                        id='dash-ok-btn'
                                        onClick={OkButtonOnClick}
                                    >
                                        {STRINGS.OK}
                                    </Button>
                                </div>
                            </div> :
                            status.doubleEntrance ?
                                <div className='dash-double-entrance-main'>
                                    {`${STRINGS.YOU_ARE_LOGGED_IN_WITH_THIS_PHONE}!`}
                                </div> :
                                status.ready ?
                                    <div className='dash-finished-successfully-main'>
                                        <span className='finished-successfully-line1'>
                                            {`${STRINGS.THE_CONNECTION_WAS_SUCCESSFUL}`}
                                        </span>
                                        {isNewSession ?
                                            <OldMessagesDropDown
                                                history={props.history}
                                                newSessionCatchup={props.location.state.data.newSessionCatchup}
                                            />
                                            :
                                            <div>
                                                <span className='finished-successfully-line2'>{` ${STRINGS.THANK_YOU}.`}</span><br/>
                                                <span className='finished-successfully-line2'>{` ${STRINGS.YOU_CAN_RECEIVE_AND_SEND}.`}</span><br/>
                                                <a
                                                    className="dash-terms-of-use-link"
                                                    href='https://mobile2crm.com'
                                                >
                                                    {STRINGS.MOBILE2CRMCOM}
                                                </a >
                                            </div>
                                        }
                                    </div> :
                                    status.agree ?
                                        <div className='dash-qrcode-div'>
                                            {qrCode ?
                                                <React.Fragment>
                                                    <h1>{STRINGS.PLEASE_SCAN_THE_BARCODE}</h1>
                                                    <div className='dash-qrcode'>
                                                        <QRCode
                                                            fgColor='#075e54'
                                                            value={qrCode}
                                                            size={300}
                                                        />
                                                    </div>
                                                </React.Fragment> :
                                                <Spinner style={spinnerStyleObj} />}
                                        </div> : null)}
                </div>
                <div className='dash-logout-btn-div'>
                    {!status.ready ?
                        <Button
                            className='dash-logout-btn'
                            onClick={logout}
                        >
                            {STRINGS.LOGOUT}
                        </Button>
                        : <React.Fragment />}
                </div>
            </div>
        );
    }
    else {
        return (<Redirect to='/login' />)
    }
}

export default Dashboard;