import React, { useState } from 'react';
import { Button, FormGroup, Input, Label, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { specificSessionSendMsg } from '../../service/requests';
import * as STRINGS from '../../strings/english';
import { formatInputValue, convertFormatNumToRegularNum, validPhoneNumber } from '../../service/tools';
import './sendMsgModal.css';

export const SendMsgFormModal = (props) => {
    const [phoneNum, setPhoneNum] = useState('');
    const [messageContent, setMessageContent] = useState('');

    const sendMsgOnClick = () => {
        const formData = {
            phoneNumber: convertFormatNumToRegularNum(phoneNum),
            messageContent: messageContent,
            sessionData: props.session,
        }
        if (!phoneNum || !messageContent) {
            return alert(STRINGS.MISSING_DETAILS);
        }
        else if (!validPhoneNumber(phoneNum)) {
            return alert(STRINGS.THE_PHONE_NUMBER_IS_INVALID);
        }
        else {
            specificSessionSendMsg(formData).then(res => {
                if (res && res.data) {
                    alert(res.data);
                    props.sendMsgFormModalToggle();
                }
            }).catch(err => {
                console.log(err);
                props.sendMsgFormModalToggle();
            })
        }

    }

    return (
        <div>
            <ModalHeader
                toggle={props.sendMsgFormModalToggle}
            >
                {STRINGS.MESSAGE_SENDING_FORM}
            </ModalHeader>
            <ModalBody>
                <div>
                    <FormGroup>
                        <Label for="send-msg-phone-num-input">
                            {STRINGS.PHONE_NUMBER}
                        </Label>
                        <Input
                            id="send-msg-phone-num-input"
                            name="send-msg-phone-num-input"
                            type="text"
                            value={formatInputValue(phoneNum)}
                            onChange={(e) => setPhoneNum(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="send-msg-message-content-input">
                            {STRINGS.MESSAGE_CONTENT}
                        </Label>
                        <Input
                            id="send-msg-message-content-input"
                            name="text"
                            type="textarea"
                            value={messageContent}
                            onChange={(e) => setMessageContent(e.target.value)}
                        />
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={props.sendMsgFormModalToggle}
                    outline
                >
                    {STRINGS.CANCEL}
                </Button>

                <Button
                    onClick={sendMsgOnClick}
                    id='send-msg-save-btn'
                >
                    {STRINGS.SEND}
                </Button>
            </ModalFooter>
        </div>
    )
}