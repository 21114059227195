const SERVER_URL = process.env.REACT_APP_SERVER_PATH;
const ADMIN_SCREEN_PATH = '/admin-screen';

export const CONSTS = {
    GET_SESSIONS: `${SERVER_URL}${ADMIN_SCREEN_PATH}/get-sessions`,
    GET_SESSIONS_FROM_SPIKKO: `${SERVER_URL}${ADMIN_SCREEN_PATH}/spikko-wa-get-sessions`,
    DISSCONNECT_SESSION_PATH: `${SERVER_URL}${ADMIN_SCREEN_PATH}/disconnect-session`,
    RELOAD_SESSION_PATH: `${SERVER_URL}${ADMIN_SCREEN_PATH}/reload-session`,
    DELETE_SESSION_PATH: `${SERVER_URL}${ADMIN_SCREEN_PATH}/delete-session`,
    SET_AS_DISCONNECTED_PATH: `${SERVER_URL}${ADMIN_SCREEN_PATH}/set-as-disconnected`,
    UPDATE_SESSION: `${SERVER_URL}${ADMIN_SCREEN_PATH}/update-session`,
    CREATE_SCHEDULE: `${SERVER_URL}${ADMIN_SCREEN_PATH}/create-schedule`,
    STOP_SCHEDULE: `${SERVER_URL}${ADMIN_SCREEN_PATH}/stop-schedule`,
    GET_USER_SCHEDULE: `${SERVER_URL}${ADMIN_SCREEN_PATH}/get-user-schedule`,
    SPECIFIC_SESSION_SEND_MSG: `${SERVER_URL}${ADMIN_SCREEN_PATH}/specific-session-send-msg`,
    NEW_SESSION_FIND_LAST_MESSAGES: `${SERVER_URL}${ADMIN_SCREEN_PATH}/new-session-find-last-messages`,
    GET_SESSIONS_FROM_SPIKKO_PATH: '/SMS/WASessions',
}