import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { IntlProvider } from 'react-intl';


ReactDOM.render(

  <BrowserRouter>
    <IntlProvider locale="en">
      <App />
    </IntlProvider>
  </BrowserRouter>, document.getElementById('root'));