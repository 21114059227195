import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

export const formatTime = (date, withMilliseconds = false, returnDefaultStr = true) => {
    if (date) {
        let d = new Date(date);
        let hours = d.getHours();
        let minutes = d.getMinutes();
        let milliseconds = d.getMilliseconds()
        let fullTime =
            `${addZeroToNumber(hours)}:${addZeroToNumber(minutes)}${withMilliseconds ? `:${addZeroToNumber(milliseconds)}` : ''}`;
        return fullTime;
    }
    else if (returnDefaultStr) {
        return ['--', '--', '--'].join(':');
    }
    else return '';
}

const addZeroToNumber = (num) => {
    if (num < 10) {
        return '0' + num;
    }
    else return num;
}

export const formatDate = (date) => {
    if (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    else return ['--', '--', '--'].join('/');
}

export const returnNumArr = (arr) => {
    if (arr) {
        let numArr = [];
        arr.forEach(element => {
            numArr.push(element.CLINumber)
        });
        return numArr;
    }
}

export function formatInputValue(value) {
    if (value) {
        const asYouType = new AsYouType();
        asYouType.input('+');
        let number = asYouType.input(value);
        return number;
    } else return '';
}

export function convertFormatNumToRegularNum(value) {
    const asYouType = new AsYouType();
    asYouType.input(value);
    return asYouType.getChars().slice(1);
}

export function validPhoneNumber(number) {
    return isValidPhoneNumber(number);
}

export function convertHoursToMs(hours) {
    return hours * 60 * 60 * 1000;
}

export function convertMinutesToMs(minutes) {
    return minutes * 60 * 1000;
}

export function checkArrayIfHasValueTrue(arr) {
    return !arr.every(item => item.active === false);
}