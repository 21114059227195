import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/dashboard';
import AdminScreen from './components/admin-screen';


function App() {
  return (
    <div className='App'>
      <Switch>
        <Route exact path='/' component={Dashboard} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/admin-screen' component={AdminScreen} />
      </Switch>
    </div>
  );
}

export default App;
